import { useParams, useNavigate } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useForm } from 'react-hook-form'
import { useState, useEffect } from 'react';
import Icons from '../../widgets/Icons'
import { customSwall } from "../../components/CustomSwal";

import noImage  from '../../assets/images/add-image.webp'
import { getDatos, mutarDatos } from '../../providers/dataServer';
import Spiner from '../../widgets/Spiner';
import { urlServerRoot } from '../../providers/serverProvider';
function AgregarModificarProducto() {

    const [ categorias , setCategorias ] = useState(null)
    const [ visualImages, setVisualImages ] = useState({
        1: { url: noImage, state : false},
        2: { url: noImage, state : false},
        3: { url: noImage, state : false},
        4: { url: noImage, state : false},
        5: { url: noImage, state : false},
    })
    const params = useParams()
    const queryClient = useQueryClient()
    const navigate = useNavigate();

    const { data: datos = {
        subcategorias: [],
        colores: [],
        tallas: [],
        tipos: [],
        materiales: [],
        modelos: [],
        cantidades: [],
      }} = useQuery(["datosInicio"], () => getDatos('/products/getDatosInicio'))

    const { isLoading: recuperandoProducto, refetch } = useQuery(["datosProducto"], () => getDatos('/products/getDatosToEdith', { idProducto: params.idProducto}), {
        refetchOnWindowFocus: false,
        enabled: false, // disable this query from automatically running
        onSuccess: (data) => {
            console.log(data)
            setValue('descripcion', data.descripcion)
            setValue('nombre', data.nombre)
            setValue('precio', data.precio)
            setValue('modificable', data.modificable)
            setValue('mutable', data.mutable)
            if (parseInt(data.descuento) > 0) {
                setValue('descuento', data.descuento)
            }
            setValue('subcategoria', data.sub_categoria)
            setValue('imagenPrincipal', parseInt(data.imagen))

            let imagenesAMostrar = {...visualImages}
            let objImagenes = JSON.parse(data.imagenes)
            Object.keys(objImagenes).map((e)=>{
                imagenesAMostrar[e].url = `${ urlServerRoot }/img-productos/${ data.carpeta }/${ objImagenes[e].tumbnail }`
                imagenesAMostrar[e].state = true
            })
            setVisualImages(imagenesAMostrar)

            let objOpciones = JSON.parse(data.opciones)
            setValue('subirImagen', objOpciones.subirImagen)
            setValue('subirLogo', objOpciones.subirLogo ?? false)
            setValue('tipos', Object.keys(objOpciones.tipos) ?? [])
            setValue('tallas', Object.keys(objOpciones.tallas) ?? [])
            setValue('materiales', Object.keys(objOpciones.materiales) ?? [])
            setValue('modelos', Object.keys(objOpciones.modelos) ?? [])
            setValue('colores', Object.keys(objOpciones.colores) ?? [])
            //Set cantidades
            setValue('weigthOtra', objOpciones.cantidades['otra']?.weight ?? '')
            setValue('weigth6', objOpciones.cantidades['6_unidades']?.weight ?? '')
            setValue('weigth12', objOpciones.cantidades['12_unidades']?.weight ?? '')
            setValue('weigthUnitOtra', objOpciones.cantidades['otra']?.weight_unit ?? 'ounce')
            setValue('weigthUnit6', objOpciones.cantidades['6_unidades']?.weight_unit ?? 'ounce')
            setValue('weigthUnit12', objOpciones.cantidades['12_unidades']?.weight_unit ?? 'ounce')
            setValue('precio6', objOpciones.cantidades['6_unidades']?.precio ?? '')
            setValue('precio12', objOpciones.cantidades['12_unidades']?.precio ?? '')

            setValue('tieneOtra', !!objOpciones.cantidades['otra'])
            setValue('tiene6', !!objOpciones.cantidades['6_unidades'])
            setValue('tiene12', !!objOpciones.cantidades['12_unidades'])

            if (!objOpciones.cantidades['otra'] && !objOpciones.cantidades['6_unidades'] && !objOpciones.cantidades['12_unidades']) {
                setValue('tieneOtra', true)
            }
        }
    })

    const { register, handleSubmit, formState: { errors }, watch, setValue } = useForm({
        defaultValues: {
            imagenPrincipal: 1,
            categoria: params.categoria,
            modificable: false,
            weigthUnitOtra: 'ounce',
            weigthUnit6: 'ounce',
            weigthUnit12: 'ounce',
            tieneOtra: false,
            tiene6: false,
            tiene12: false
        },
    })

    const { mutate: guardarProducto, isLoading } = useMutation({
        mutationFn: (datos) => mutarDatos({ruta: '/products/nuevoProducto', parametros: datos})
    })

    const { mutate: actualizarProducto, isLoading: actualizando } = useMutation({
        mutationFn: (datos) => mutarDatos({ruta: '/products/actualizarProducto', parametros: datos})
    })

    useEffect(() => {
        if (params.idProducto > 0) {
            queryClient.removeQueries({ queryKey: ['datosProducto'] })
            refetch()
        }
        let categorias = [];
        try {
            categorias = queryClient.getQueryData(['categorias'])
        } catch (error) {
            console.log(error)
            navigate('/')
            return
        }
        setCategorias(categorias)
        if (params.idProducto.toString() === '0') {
            let subcategoriasSelect = [];
            for (let i = 0; i < datos?.subcategorias.length; i++) {
                const element = datos?.subcategorias[i];
                if (element?.id_categoria.toString() === params.categoria) {
                    //setValue('subcategoria', element?.id)
                    subcategoriasSelect.push(element)
                }
            }
            if (subcategoriasSelect.length === 0) {
                customSwall.fire({
                    title: 'No hay subcategorias',
                    text: 'Crea primero una subcategoria para esta categoria para añadir productos'
                }).then(r => navigate(-1))
            }
        }
    }, [params, datos?.subcategorias])

    function isAnimatedGif(src) {
        return new Promise((resolve ,reject)=>{
            var request = new XMLHttpRequest();
            request.open('GET', src, true);
            request.addEventListener('load', function () {
                if(request.response.indexOf("ANMF") != -1){
                    // animated
                    resolve(1);
                }
                else{
                    // non animated
                    resolve(0);
                }
            });
            request.send();
        });
    }

    function addImage(index) {
        let inputFile = document.getElementById("input-fotos"+index);
        let canvas = document.getElementById("canvas");
        inputFile.click();
        inputFile.onchange = function () {

            const [file] = this.files
            let fileUrl;

            if (file) {
                fileUrl = URL.createObjectURL(file);
                isAnimatedGif(fileUrl).then(res => {
                    if (!res) {
                        const image = new Image();
                        image.onload = () => {
                            let w = document.getElementsByClassName('img-prodcuto-form')[0].offsetWidth;
            
                            canvas.width = w;
                            canvas.height = w;
            
                            const ladoMasPeque = Math.min(image.naturalWidth, image.naturalHeight);
            
                            let dist = (Math.max(image.naturalWidth, image.naturalHeight) -  Math.min(image.naturalWidth, image.naturalHeight)) / 2;
            
                            let x = (image.width > image.height) ? dist : 0;
                            let y = (image.width < image.height) ? dist : 0;
            
                            const ctx = canvas.getContext('2d');
            
                            var sourceX = x;
                            var sourceY = y;
                            var sourceWidth = ladoMasPeque;
                            var sourceHeight = ladoMasPeque;
                            var destWidth = w//sourceWidth;
                            var destHeight = w//sourceHeight;
                            var destX = 0//canvas.width / 2 - destWidth / 2;
                            var destY = 0//canvas.height / 2 - destHeight / 2;
            
                            ctx.drawImage(image, sourceX, sourceY, sourceWidth, sourceHeight, destX, destY, destWidth, destHeight);
                            const dataURL = canvas.toDataURL('image/png');
                            let imagenesAMostrar = {...visualImages}
                            imagenesAMostrar[index].url = dataURL
                            imagenesAMostrar[index].state = true
                            setVisualImages(imagenesAMostrar)
                        };
                        image.src = fileUrl;
                    } else {
                        let imagenesAMostrar = {...visualImages}
                        imagenesAMostrar[index].url = noImage
                        imagenesAMostrar[index].state = false
                        setVisualImages(imagenesAMostrar)
                        inputFile.value = ''
                        customSwall.fire({
                            title: 'Error!',
                            text: 'Ésta imagen no está soportada',
                            icon: 'error',
                            confirmButtonText: 'OK'
                        }).then( _ => {return});
                    }

                });
            } else {
                let imagenesAMostrar = {...visualImages}
                imagenesAMostrar[index].url = noImage
                imagenesAMostrar[index].state = false
                setVisualImages(imagenesAMostrar)
                inputFile.value = ''

            }
        }
    }

    function setPrincipal( e, index ) {
        e.stopPropagation();
        setValue('imagenPrincipal', index)
    }

    function eliminarImagen(e, index) {
        e.stopPropagation();
        if (watch('imagenPrincipal') === index) {
            customSwall.fire({
                title: 'Error!',
                text: 'No se puede eliminar la imagen principal, primero elige otra como principal y luego elimina esta',
                icon: 'error',
                confirmButtonText: 'OK'
            });
            return
        }
        let states = [
            visualImages[1].state,
            visualImages[2].state,
            visualImages[3].state,
            visualImages[4].state,
            visualImages[5].state,
        ]
        states[index - 1] = false;
        console.log(states)
        let todosVacios = true;
        for (let i = 0; i < states.length; i++) {
            if (states[i] === true) {
                todosVacios = false;
                break;
            }
        }

        if (todosVacios) {
            customSwall.fire({
                title: 'Error!',
                text: 'Debes tener por lo menos una imagen del producto',
                icon: 'error',
                confirmButtonText: 'OK'
            });
            return
        }

        let inputFile = document.getElementById("input-fotos"+index);
        inputFile.value = ''

        let imagenesAMostrar = {...visualImages}
        imagenesAMostrar[index].url = noImage
        imagenesAMostrar[index].state = false
        setVisualImages(imagenesAMostrar)
    }

    const onSubmit = handleSubmit(datos => {
        console.log(datos)
        let hayImagen = false;
        for (let i = 0; i < 5; i++) {
            if (visualImages[i+1].state) {
                hayImagen = true
                break
            }
        }
        if (!hayImagen) {
            customSwall.fire({
                title: 'Error!',
                text: 'Debes tener por lo menos una imagen del producto',
                icon: 'error',
                confirmButtonText: 'OK'
            });
            return
        }
        if (params.idProducto > 0) {
            datos['img1'] = visualImages[1].state ? 1 : 0
            datos['img2'] = visualImages[2].state ? 1 : 0
            datos['img3'] = visualImages[3].state ? 1 : 0
            datos['img4'] = visualImages[4].state ? 1 : 0
            datos['img5'] = visualImages[5].state ? 1 : 0
            console.log(datos)
            actualizarProducto(datos, {
                onSuccess: (data) => {
                    console.log(data)
                    if (data.ok) {
                        customSwall.fire({
                            icon: 'success',
                            title: `Actualizado`,
                            text: `Se actualizó el producto correctamente!`,
                            showConfirmButton: true,
                        }).then( response => {
                            queryClient.invalidateQueries([`productos, ${ params.categoria }`])
                            navigate(-1)
                        })
                    } else {
                        customSwall.fire({
                            icon: 'error',
                            title: 'Error',
                            text: !!data.error ? data.error : 'ocurrio un error',
                            showConfirmButton: true,
                        })
                    }
                },
            })
        } else {
            guardarProducto(datos, {
                onSuccess: (data) => {
                    console.log(data)
                    if (data.ok) {
                        customSwall.fire({
                            icon: 'success',
                            title: `Agregado`,
                            text: `Se agregó el producto correctamente!`,
                            showConfirmButton: true,
                        }).then( response => {
                            queryClient.invalidateQueries([`productos, ${ params.categoria }`])
                            navigate(-1)
                        })
                    } else {
                        customSwall.fire({
                            icon: 'error',
                            title: 'Error',
                            text: !!data.error ? data.error : 'ocurrio un error',
                            showConfirmButton: true,
                        })
                    }
                },
            })
        }
    })

    return (
        <div className='cont-lista-productos-tabla'>
            { (isLoading || recuperandoProducto || actualizando)&& <Spiner title={"Procesando..."} />}
            <div className='header-productos'>
                <h1 className='prod-titulo'>{ params.idProducto > 0 ? 'Modificar Producto' : 'Agregar Producto' }</h1>
                <div className='cont-header-prod-buttons'>
                    <button type='submit' form='form-agregar-modificar' className="btn btn-azul">Guardar</button>
                    <button onClick={ () => navigate(-1) } className="btn btn-rojo">Cancelar</button>
                </div>
            </div>
            <form id='form-agregar-modificar' onSubmit={ onSubmit }>
                <h2 className='form-product-title-section'>Datos del Producto</h2>
                {params.idProducto > 0 && <input hidden value={ params.idProducto } {...register("id")} />}
                <div className="form-row">
                    <div className='form-images-producto'>
                        <label className='label-form-group-unit' htmlFor="images">Imagenes del producto:</label>
                        <canvas hidden id="canvas"></canvas>
                        <div className='container-imagenes-producto'>
                            {
                                [1,2,3,4,5].map((item, index) => (
                                <div key={ index } id={`div-image${item}`} onClick={ () => addImage(item) } className='img-prodcuto-form' style={{ backgroundImage: `url(${visualImages[item].url})` }}>
                                    { visualImages[item].state && <>

                                    <div onClick={ (e) => setPrincipal( e, item ) } className={`cont-img-form-prod-icon icon-home ${ watch('imagenPrincipal') === item && ' icon-home-active' }`}>
                                        <Icons req={ 'casa' } fill='var(--blanco)' />
                                    </div>
                                    <div onClick={ (e) => eliminarImagen(e, item) } className='cont-img-form-prod-icon icon-del'>
                                        <Icons req={ 'delete' } fill='var(--blanco)' />
                                    </div>

                                </>}
                                </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
                <input hidden type="file" id="input-fotos1" accept="image/png, image/jpeg, image/webp" {...register("file1")} />
                <input hidden type="file" id="input-fotos2" accept="image/png, image/jpeg, image/webp" {...register("file2")} />
                <input hidden type="file" id="input-fotos3" accept="image/png, image/jpeg, image/webp" {...register("file3")} />
                <input hidden type="file" id="input-fotos4" accept="image/png, image/jpeg, image/webp" {...register("file4")} />
                <input hidden type="file" id="input-fotos5" accept="image/png, image/jpeg, image/webp" {...register("file5")} />
                <div className="form-row">
                    <div className="cont-form-item cont-form-item-row-1">
                        <label className='label-form-group-unit' htmlFor="nombre">Nombre del producto:</label>
                        <input type="text" placeholder="Anillos de Oro 18k" { ...register('nombre', {
                            required: {
                                value: true,
                                message: 'El nombre del producto es obligatorio'
                            },
                        }) }/>
                        <span>{ !!errors['nombre'] && errors['nombre'].message }</span>
                    </div>
                </div>
                <div className="form-row">
                    <div className="cont-form-item">
                        <label className='label-form-group-unit' htmlFor="categoria">Categoría:</label>
                        <select value={ watch('categoria') } { ...register('categoria') } disabled={params.idProducto > 0}>
                            {categorias?.map((cat)=>(
                            <option key={ cat.id } value={ cat.id }>{ cat.nombre }</option>))}
                        </select>
                        <span>{ !!errors['categoria'] && errors['categoria'].message }</span>
                    </div>
                    <div className="cont-form-item cont-checks-box-items">
                        <label className='label-form-group-unit' htmlFor="subcategoria">Subcategoria:</label>
                        <select value={ watch('subcategoria') } { ...register('subcategoria') }>
                            {datos?.subcategorias?.map((cat) => {
                                if (cat.id_categoria.toString() === watch('categoria')) {
                                    return <option key={ cat.id } value={ cat.id }>{ cat.nombre }</option>
                                }
                            })}
                        </select>
                        <span>{ !!errors['subcategoria'] && errors['subcategoria'].message }</span>
                    </div>
                </div>
                <div className="form-row" style={ { justifyContent: 'flex-start' }}>
                    <label className="switch">
                        <input type="checkbox" defaultChecked={ watch('modificable') } { ...register('modificable', {
                            onChange: e => {if (e.target.checked) { setValue('mutable', false) }}
                        }) } />
                        <span className="slider round"></span>
                    </label>&nbsp;Este producto se cotiza
                </div>
                {!watch('modificable') && <div className="form-row" style={ { justifyContent: 'flex-start' }}>
                    <label className="switch">
                        <input type="checkbox" defaultChecked={ watch('mutable') } { ...register('mutable', {
                            onChange: e => {if (watch('modificable')) { e.target.checked = false }}
                        }) } />
                        <span className="slider round"></span>
                    </label>&nbsp;Cotizable si no es blanco o negro
                </div>}
                { !watch('modificable') && <div className="form-row">
                    <div className="cont-form-item cont-form-item-row-2">
                        <label className='label-form-group-unit' htmlFor="precio">Precio:</label>
                        <input type="number" placeholder="69.99" step={0.01} { ...register('precio', {
                            required: {
                                value: true,
                                message: 'El precio del producto es obligatorio'
                            },
                        }) }/>
                        <span>{ !!errors['precio'] && errors['precio'].message }</span>
                    </div>
                    <div className="cont-form-item cont-form-item-row-2">
                        <label className='label-form-group-unit' htmlFor="descuento">% Descuento:</label>
                        <input type="number" placeholder="25" { ...register('descuento') }/>
                    </div>
                </div>}
                <div className="form-row">
                    <div className="cont-form-item cont-form-item-row-1">
                        <label htmlFor="descripcion">Descripción:</label>
                        <textarea cols="30" rows="10" { ...register('descripcion', {
                            required: {
                                value: true,
                                message: 'La descripción es obligatoria para cada producto'
                            },
                        }) }></textarea>
                        <span>{ !!errors['descripcion'] && errors['descripcion'].message }</span>
                    </div>
                </div>
                <h2 className='form-product-title-section'>Opciones que podrá elegir el cliente para comprar o cotizar este producto</h2>
                <div className="form-row" style={ { justifyContent: 'flex-start' }}>
                    <label className="switch">
                        <input type="checkbox" defaultChecked={ watch('subirImagen') } { ...register('subirImagen') } />
                        <span className="slider round"></span>
                    </label>&nbsp;Sube tu imagen
                </div>
                <div className="form-row" style={ { justifyContent: 'flex-start' }}>
                    <label className="switch">
                        <input type="checkbox" defaultChecked={ watch('subirLogo') } { ...register('subirLogo') } />
                        <span className="slider round"></span>
                    </label>&nbsp;Sube tu logo
                </div>
                <div className="form-row">
                    <div className="cont-form-item cont-checks-options">
                        <label className='label-form-group-unit' htmlFor="subcategorias">Tipos:</label>
                        <div className='cont-checkboxs-subcategorias'>
                        {datos?.tipos?.map((tipo, index) => (
                            <div className='cont-checkbox-tipo' key={ index }>
                                <label htmlFor={ tipo.nombre }>{ tipo.nombre }</label>
                                <input type="checkbox" value={ tipo.codigo } { ...register(`tipos`) }/>
                            </div>
                        ))}
                        </div>
                    </div>
                </div>
                <div className="form-row">
                    <div className="cont-form-item cont-checks-options">
                        <label className='label-form-group-unit' htmlFor="subcategorias">Materiales:</label>
                        <div className='cont-checkboxs-subcategorias'>
                        {datos?.materiales?.map((tipo, index) => (
                            <div className='cont-checkbox-tipo' key={ index }>
                                <label htmlFor={ tipo.nombre }>{ tipo.nombre }</label>
                                <input type="checkbox" value={ tipo.codigo } { ...register(`materiales`) }/>
                            </div>
                        ))}
                        </div>
                    </div>
                </div>
                <div className="form-row">
                    <div className="cont-form-item cont-checks-options">
                        <label className='label-form-group-unit' htmlFor="subcategorias">Modelos:</label>
                        <div className='cont-checkboxs-subcategorias'>
                        {datos?.modelos?.map((tipo, index) => (
                            <div className='cont-checkbox-tipo' key={ index }>
                                <label htmlFor={ tipo.nombre }>{ tipo.nombre }</label>
                                <input type="checkbox" value={ tipo.codigo } { ...register(`modelos`) }/>
                            </div>
                        ))}
                        </div>
                    </div>
                </div>
                <div className="form-row">
                    <div className="cont-form-item cont-checks-options">
                        <label className='label-form-group-unit' htmlFor="subcategorias">Tallas:</label>
                        <div className='cont-checkboxs-subcategorias'>
                        {datos?.tallas?.map((tipo, index) => (
                            <div className='cont-checkbox-tipo' key={ index }>
                                <label htmlFor={ tipo.nombre }>{ tipo.nombre }</label>
                                <input type="checkbox" value={ tipo.codigo } { ...register(`tallas`) }/>
                            </div>
                        ))}
                        </div>
                    </div>
                </div>
                <div className="form-row">
                    <div className="cont-form-item cont-checks-options">
                        <label className='label-form-group-unit' htmlFor="subcategorias">Colores:</label>
                        <div className='cont-checkboxs-subcategorias'>
                        {datos?.colores?.map((tipo, index) => (
                            <div className='cont-checkbox-tipo' key={ index }>
                                <label htmlFor={ tipo.nombre }><div style={ { backgroundColor: tipo.codigo, width: '10px', height: '10px', display: 'inline-block', marginRight: '10px' } }></div>{ tipo.nombre }</label>
                                <input type="checkbox" value={ tipo.id } { ...register(`colores`) }/>
                            </div>
                        ))}
                        </div>
                    </div>
                </div>
                <h2 className='form-product-title-section'>Cantidades - Precio</h2>
                <div className="form-row cont-canti">
                    <div className="cantid">
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <input style={{ marginRight: '10px', width: '20px', height: '20px' }} type="checkbox" checked={(watch('tieneOtra') || (!watch('tiene6') && !watch('tiene12')))} { ...register('tieneOtra')}/>
                            <label className='label-form-group-unit' htmlFor="otra">Otra</label>
                        </div>
                    </div>
                    <div className="cantid">
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <input style={{ marginRight: '10px', width: '20px', height: '20px' }} type="checkbox" { ...register('tiene6')}/>
                            <label className='label-form-group-unit' htmlFor="subcategorias">6 Unidades</label>
                        </div>
                        { watch('tiene6') && <div className='inputs-cant'>
                            { !watch('modificable') && <div className="cont-form-item cont-form-item-row-1">
                                <label className='label-form-group-unit' htmlFor="precio6" style={{ fontSize: '12px' }}>Precio para esta cantidad:</label>
                                <input type="number" placeholder="22.50" step={0.01} { ...register('precio6', {
                                    required: {
                                        value: true,
                                        message: 'Completa este campo'
                                    },
                                }) }/>
                                <span>{ !!errors['precio6'] && errors['precio6'].message }</span>
                            </div>}
                        </div>}
                    </div>
                    <div className="cantid">
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <input style={{ marginRight: '10px', width: '20px', height: '20px' }} type="checkbox" { ...register('tiene12')}/>
                            <label className='label-form-group-unit' htmlFor="subcategorias">12 Unidades</label>
                        </div>
                        { watch('tiene12') && <div className='inputs-cant'>
                           { !watch('modificable') && <div className="cont-form-item cont-form-item-row-1">
                                <label className='label-form-group-unit' htmlFor="precio12" style={{ fontSize: '12px' }}>Precio para esta cantidad:</label>
                                <input type="number" placeholder="45.00" step={0.01} { ...register('precio12', {
                                    required: {
                                        value: true,
                                        message: 'Completa este campo'
                                    },
                                }) }/>
                                <span>{ !!errors['precio12'] && errors['precio12'].message }</span>
                            </div>}
                        </div>}
                    </div>
                </div>
            </form>
        </div>
    )
}

export default AgregarModificarProducto