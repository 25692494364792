import { Routes, Route } from 'react-router-dom';
import { useEffect, useState } from 'react';
import './assets/css/App.css'
import Spiner from './widgets/Spiner';
import { customSwall } from "./components/CustomSwal";

import DashBoard from './pages/DashBoard'
import DashboardProductos from './pages/outlets/DashboardProducts'
import DashboardInicio from './pages/outlets/DashboardInicio';
import AgregarModificarProducto from './pages/outlets/AgregarModificarProducto'
import { urlServer } from './providers/serverProvider';
import Login from './pages/Login';
import ConfigsApp from './pages/outlets/ConfigsApp';
import Cotizaciones from './pages/outlets/Cotizaciones';
import Cotizacion from './pages/outlets/Cotizacion';
import Compras from './pages/outlets/Compras';
import Compra from './pages/outlets/Compra';

function App() {

  const [ isLogin, setIsLogin ] = useState(false);
  const [ isLoading, setIsLoading ] = useState(true)

  async function comprobarLogin() {
    const token = localStorage.getItem('tempToken') ?? ''
    if ( token === '' ) {
      customSwall.fire({
        title: 'Error!',
        text: 'Ésta sesión de administrador a caducado o ya no es válida, vuelve a ingresar desde la tienda',
        icon: 'error',
        confirmButtonText: 'OK'
      }).then( () => {
        setIsLogin(false)
        setIsLoading(false)
      });
      return
    }
    let formData = new FormData();
      formData.append('token', token);
      await fetch(`${ urlServer }/auth/valid-token`, {
          method: 'POST',
          body: formData,
      })
      .then((response) => response.json())
      .then((data) => {
          if (data.ok) {
            setIsLogin(true)
          } else {
            customSwall.fire({
              title: 'Error!',
              text: 'Ésta sesión de administrador a caducado o ya no es válida, vuelve a ingresar desde la tienda',
              icon: 'error',
              confirmButtonText: 'OK'
            }).then( setIsLogin(false) );
          }
      })
      .catch(error => {
        customSwall.fire({
          title: 'Error!',
          text: 'Ésta sesión de administrador a caducado o ya no es válida, vuelve a ingresar desde la tienda',
          icon: 'error',
          confirmButtonText: 'OK'
        }).then( setIsLogin(false) );
      }).finally(()=>setIsLoading(false))
  }
  

  useEffect(() => {
    comprobarLogin()
  }, [])

  return (
    <Routes>
      <Route path="/" element={isLoading ? <Spiner/> : (isLogin ? <DashBoard /> : <Login/>)}>
        <Route index element={ <DashboardInicio /> }/>
        <Route path="productos/:categoria" element={<DashboardProductos />} />
        <Route path="agregar-modificar-producto/:idProducto/:categoria" element={<AgregarModificarProducto />} />
        <Route path="configs" element={<ConfigsApp />} />
        <Route path="cotizaciones" element={<Cotizaciones/>} />
        <Route path="cotizacion/:id" element={<Cotizacion />} />
        <Route path="compras" element={<Compras/>} />
        <Route path="compra/:id" element={<Compra />} />
      </Route>
      <Route path='/login' element={ <Login/> }></Route>
    </Routes>
  )
}

export default App;
