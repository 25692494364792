import { useMutation, useQuery, useQueryClient } from 'react-query'
import { getDatos, mutarDatos } from '../../providers/dataServer';
import { useEffect, useState } from 'react';
import Spiner from '../../widgets/Spiner';
import { useForm } from 'react-hook-form';
import { customSwall } from '../../components/CustomSwal';

function TablaInicio({ tabla, elements, acciones = true}) {

  const [ mostrarModal, setMostrarModal ] = useState(false)
  const [ elementToEdit, setElementToEdit ] = useState([])
  const queryClient = useQueryClient()
  const { mutate: delItemTabla, isLoading: borrandoItem } = useMutation({
    mutationFn: (datos) => mutarDatos({ruta: tabla === 'subcategorias' ? '/products/delSubcategoria' : '/products/delItemTabla', parametros: datos})
  })

  function eliminarElemento(id) {
    customSwall.fire({
      icon: 'question',
      title: 'Eliminar?',
      text: 'Se eliminará este elemento',
      confirmButtonText: `Si, Eliminar`,
      showCancelButton: true,
      cancelButtonText: 'Cancelar'
    }).then(result => {
        if  ( result.isConfirmed ) {
          delItemTabla({id: id, tabla: tabla}, {
                onSuccess: (data)=>{
                    if (data.ok) {
                      queryClient.invalidateQueries([`datosInicio`])
                    } else {
                      customSwall.fire({
                            icon: 'error',
                            title: 'Error',
                            text: data.error ?? `No se pudo completar la operación`
                        })
                    }
                }
            })
        }
    })
  }

  function editar(element) {
    setElementToEdit(element)
    setMostrarModal(true)
  }

  return <>
    { borrandoItem && <Spiner/> }
    { mostrarModal && <ModalInicio tabla={ tabla } cerrar={ () => setMostrarModal(false) } element={elementToEdit} /> }
    <table id="tabla-productos" className="tabla-productos inicio">
      <thead>
          <tr>
              <th width={ '20px' } >#</th>
              { tabla === 'subcategorias'  && <th>Categoria</th>}
              <th>Nombre</th>
              { acciones && <th>Acciones</th>}
          </tr>
      </thead>
      <tbody>
          {
          elements?.map((p, index) =>  (
              <tr key={ p.id }>
                  <td width={ '20px' }>{ index + 1 }</td>
                  { tabla === 'subcategorias'  && <td>{ p.categoriaName }</td>}
                  <td>
                    {tabla === 'colores' && <div style={ { backgroundColor: p.codigo, width: '10px', height: '10px', display: 'inline-block', marginRight: '10px' } }></div>}{ p.nombre }
                  </td>
                  { acciones && <td className='td-acciones'>
                      { p.nombre !== 'Otra' && <button onClick={ () => editar(p) }className="btn-edit-tabla-inicio">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill='black' d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z"/></svg>
                      </button>}
                      { p.nombre !== 'Otra' && <button onClick={ ()=>eliminarElemento(p.id) } className="btn-edit-tabla-inicio">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill='black' d="M576 128c0-35.3-28.7-64-64-64H205.3c-17 0-33.3 6.7-45.3 18.7L9.4 233.4c-6 6-9.4 14.1-9.4 22.6s3.4 16.6 9.4 22.6L160 429.3c12 12 28.3 18.7 45.3 18.7H512c35.3 0 64-28.7 64-64V128zM271 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z"/></svg>
                      </button>}
                  </td>}
              </tr>
          )) ?? <p>No hay datos</p>}
      </tbody>
    </table>
  </>
}

function ModalInicio({ tabla, cerrar, element = [] }) {

  const queryClient = useQueryClient()
  const [ select, setSelect ] = useState([])
  const [ esEditar, setEsEditar ] = useState(false)

  const { register, handleSubmit, formState: { errors }, setValue } = useForm({
    defaultValues: {
      tabla: tabla,
    },
  })

  useEffect(() => {
    const isEmpty = Object.keys(element).length === 0;
    if (!isEmpty) {
      setEsEditar(true)
      setValue('nombre', element.nombre)
      setValue('id', element.id)
      if (tabla === 'colores') {
        setValue('color', element.codigo)
      }
    }
    try {
      let contSelect = tabla == 'subcategorias' ? 'categorias' : ''
      setSelect(queryClient.getQueryData(contSelect))
    } catch (error) {
      return
    }
  }, [])

  const { mutate: addItemTabla, isLoading: guardandoItem } = useMutation({
    mutationFn: (datos) => mutarDatos({ruta: esEditar ? '/products/setItemTabla' : '/products/addItemTabla', parametros: datos})
  })

  function onSubmit(data) {
    addItemTabla(data, {
      onSuccess: (res) => {
        if (res.ok) {
          queryClient.invalidateQueries([`datosInicio`])
        } else {
          customSwall.fire({
            icon: 'error',
            title: 'Error',
            text: !!res.error ? res.error : 'ocurrio un error',
            showConfirmButton: true,
          })
        }
        cerrar()
      }
    })
  }

  return <div className='modal-tabla-inicio'>
    { guardandoItem && <Spiner/> }
    <div className='modal'>
      <div className='header'>
        <h3>{esEditar ? 'Editar' : 'Agregar'} { tabla }</h3>
        <button onClick={ cerrar } >Cerrar</button>
      </div>
      <form onSubmit={ handleSubmit(onSubmit) } >
        { (tabla == 'subcategorias' && !esEditar)  && <div className="cont-form-item cont-form-item-row-1">
          <label className='label-form-group-unit' htmlFor="categoria">Categoria:</label>
          <select { ...register('categoria', {
              required: {
                  value: true,
                  message: 'Elige la categoría'
              },
          }) }>
            <option value=''>Elige la categoría</option>
            {
              select?.map((s, index) => <option key={ index } value={ s.id }>{s.nombre}</option>)
            }
          </select>
          <span>{ !!errors['categoria'] && errors['categoria'].message }</span>
        </div>}
        { tabla == 'colores' && <div className="cont-form-item cont-form-item-row-1">
          <label className='label-form-group-unit' htmlFor="categoria">Color:</label>
          <input type='color' style={ { height: '60px', margin: '0 auto' } } { ...register('color', {
              required: {
                  value: true,
                  message: 'Elige el color'
              },
          }) }/>
          <span>{ !!errors['color'] && errors['color'].message }</span>
        </div>}
        <div className="cont-form-item cont-form-item-row-1">
          <label className='label-form-group-unit' htmlFor="usuario">Nombre:</label>
          <input type="text" placeholder="nombre" { ...register('nombre', {
              required: {
                  value: true,
                  message: 'El nombre es requerido'
              },
          }) }/>
          <span>{ !!errors['nombre'] && errors['nombre'].message }</span>
        </div>
        <button className='btn btn-azul'>Guardar</button>
      </form>
    </div>
  </div>
}

function DashboardInicio() {

  const [ tabla, setTabla ] = useState('')
  const [ mostrarModal, setMostrarModal ] = useState(false)

  const { data: datos = {
    subcategorias: [],
    colores: [],
    tallas: [],
    tipos: [],
    materiales: [],
    modelos: [],
    cantidades: [],
  }} = useQuery(["datosInicio"], () => getDatos('/products/getDatosInicio'))

  function agregar(tabla) {
    setTabla(tabla)
    setMostrarModal(true)
  }
  
    return (<>
    { mostrarModal && <ModalInicio tabla={ tabla } cerrar={ () => setMostrarModal(false) } /> }
      <div className="cont-inicio">
        <div className="cont-row-inicio">
          <div className="cont-table-inicio">
            <div className='cont-title-inicio-tabla'>
              <h2>Subcategorías</h2>
              <button className="btn btn-azul" onClick={ ()=>agregar('subcategorias')}>+ Agregar</button>
            </div>
            <TablaInicio tabla={ 'subcategorias'} elements={ datos.subcategorias } />
          </div>
          <div className="cont-table-inicio">
            <div className='cont-title-inicio-tabla'>
              <h2>Colores</h2>
              <button className="btn btn-azul" onClick={ ()=>agregar('colores')}>+ Agregar</button>
            </div>
            <TablaInicio tabla={ 'colores'} elements={ datos.colores } />
          </div>
          <div className="cont-table-inicio">
            <div className='cont-title-inicio-tabla'>
              <h2>Tallas</h2>
              <button className="btn btn-azul" onClick={ ()=>agregar('tallas')}>+ Agregar</button>
            </div>
            <TablaInicio tabla={ 'tallas'} elements={ datos.tallas } />
          </div>
        </div>
        <div className="cont-row-inicio">
          <div className="cont-table-inicio">
            <div className='cont-title-inicio-tabla'>
              <h2>Tipos</h2>
              <button className="btn btn-azul" onClick={ ()=>agregar('tipos')}>+ Agregar</button>
            </div>
            <TablaInicio tabla={ 'tipos'} elements={ datos.tipos }/>
          </div>
          <div className="cont-table-inicio">
            <div className='cont-title-inicio-tabla'>
              <h2>Materiales</h2>
              <button className="btn btn-azul" onClick={ ()=>agregar('materiales')}>+ Agregar</button>
            </div>
            <TablaInicio tabla={ 'materiales'} elements={ datos.materiales }/>
          </div>
          <div className="cont-table-inicio">
            <div className='cont-title-inicio-tabla'>
              <h2>Modelos</h2>
              <button className="btn btn-azul" onClick={ ()=>agregar('modelos')}>+ Agregar</button>
            </div>
            <TablaInicio tabla={ 'modelos'} elements={ datos.modelos }/>
          </div>
        </div>
        <div className="cont-row-inicio">
          <div className="cont-table-inicio">
            <div className='cont-title-inicio-tabla'>
              <h2>Cantidades</h2>
            </div>
            <TablaInicio tabla={ 'cantidades'} elements={ datos.cantidades } acciones={false}/>
          </div>
        </div>
      </div>
    </>
    )
  }
  
  export default DashboardInicio;