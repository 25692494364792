import { useParams, useNavigate } from 'react-router-dom';
import { useQueryClient } from 'react-query'
import { useState, useEffect } from 'react';
import TablaProductos from '../../widgets/TablaProductos';

function DashboardProductos() {

  const [ categoria , setCategoria ] = useState(null)
  const params = useParams()
  const queryClient = useQueryClient()
  const navigate = useNavigate();

  useEffect(() => {
    let categorias = [];
    try {
      categorias = queryClient.getQueryData(['categorias'])
    } catch (error) {
      navigate('/')
      return
    }
    
    for(let i in categorias){
      if (params.categoria === categorias[i].cod_categoria) {
        setCategoria(categorias[i])
      }
    }
  }, [params])

  return (
    <div className='cont-lista-productos-tabla'>
      <div className='header-productos'>
        <h1 className='prod-titulo'>{ categoria?.nombre ?? 'Categoría' }</h1>
        <div className='cont-header-prod-buttons'>
          <button onClick={ () => navigate(`/agregar-modificar-producto/0/${ categoria.id ?? 0 }`) } className="btn btn-verde">Agregar Producto</button>
        </div>
      </div>
      <TablaProductos categoria={ categoria?.id }/>
    </div>
  )
}
  
export default DashboardProductos;