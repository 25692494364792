import { useMutation, useQuery, useQueryClient } from "react-query";
import Spiner from "../../widgets/Spiner";
import { getDatos, mutarDatos } from "../../providers/dataServer";
import moreImg from '../../assets/images/add-image.webp'
import { urlServerRoot } from "../../providers/serverProvider";
import Icons from "../../widgets/Icons";
import { customSwall } from "../../components/CustomSwal";

export default function ConfigsApp() {

    const queryClient = useQueryClient()

    const { data: imagenesCarusel = [] } = useQuery(["imagenesCarusel"], () => getDatos('/config/fotosPortada/get'))
    const { data: promociones = [{ activo: false, promo: '' }] } = useQuery(["bannerPromo"], () => getDatos('/config/configPromo/getPromos'))

    const { mutate: subirImagen, isLoading } = useMutation({
        mutationFn: (datos) => mutarDatos({ruta: '/config/fotosPortada/upload', parametros: datos})
    })
    const { mutate: delImagen, isLoading: isDel } = useMutation({
        mutationFn: (datos) => mutarDatos({ruta: '/config/fotosPortada/delete', parametros: datos})
    })
    const { mutate: activarPromo, isLoading: isActiv } = useMutation({
        mutationFn: (datos) => mutarDatos({ruta: '/config/configPromo/setPromo', parametros: datos})
    })

    function subirImagenR() {
        let input = document.getElementById('imageInput')
        input .click()
        input.onchange = function () {
            const [file] = this.files
            if (file) {
                subirImagen({imagenNew: file}, {
                    onSuccess: (data) => {
                        if (data.ok) {
                            queryClient.invalidateQueries([`imagenesCarusel`])
                        } else {
                            customSwall.fire({
                                title: 'Error!',
                                text: data.error ?? 'no se pudo realizar esta operación',
                                icon: 'error',
                                confirmButtonText: 'OK'
                            });
                        }
                    }
                })
            }
        }
        console.log(input.files)
    }

    function eliminarImagen(e, id) {
        e.stopPropagation();
        customSwall.fire({
            title: 'Eliminar?',
            text: 'Deseas eliminar esta imagen del carusel principal?',
            icon: 'question',
            confirmButtonText: 'Si, eliminar',
            showCancelButton: true,
            cancelButtonText: 'Cancelar'
        }).then(r => {
            if (r.isConfirmed) {
                delImagen({id: id}, {
                    onSuccess: (data) => {
                        if (data.ok) {
                            queryClient.invalidateQueries([`imagenesCarusel`])
                        } else {
                            customSwall.fire({
                                title: 'Error!',
                                text: data.error ?? 'no se pudo realizar esta operación',
                                icon: 'error',
                                confirmButtonText: 'OK'
                            });
                        }
                    }
                })
            }
        });
    }

    function activarPromoBanner(id) {
        let input = document.getElementById(`inputPromo_${ id }`)
        let checkbox = document.getElementById(`checkboxPromo_${ id }`)

        activarPromo({id: id, activo: checkbox.checked, promo: input.value }, {
            onSuccess: (data) => {
                if (data.ok) {
                    queryClient.invalidateQueries([`bannerPromo`])
                } else {
                    customSwall.fire({
                        title: 'Error!',
                        text: data.error ?? 'no se pudo realizar esta operación',
                        icon: 'error',
                        confirmButtonText: 'OK'
                    });
                }
            }
        })
            
        console.log(input.files)
    }

    return <div className="cont-root-configs">
        { (isLoading || isDel || isActiv) && <Spiner/> }
        <div className="row-config">
            <h1>Imagenes carusel principal</h1>
            <div className="cont-row-config">
                <div className="cont-imagenes-carrusel-p">
                    {
                        imagenesCarusel?.map((imagen, index) => {
                            return <div className="imagen-carusel-principal" key={index} style={{ backgroundImage: `url("${ urlServerRoot }/images/carusel-banner-inicial/${  imagen.ruta }")` }}>
                                <div onClick={ (e) => eliminarImagen(e, imagen.id) } className='cont-img-form-prod-icon icon-del'>
                                    <Icons req={ 'delete' } fill='var(--blanco)' />
                                </div>
                            </div>
                        })
                    }
                    <div onClick={ subirImagenR } className="imagen-carusel-principal" style={{ backgroundImage: `url("${ moreImg }")` }}></div>
                    <input id="imageInput" hidden type="file" name="imagenNew" accept="image/png, image/jpeg, image/webp" />
                </div>
            </div>
        </div>
        <div className="row-config">
            <h1>Banner Promoción</h1>
            {
                promociones?.map((promo, index) => {
                    return <div key={index} className="cont-row-config">
                        <div className="cont-swith">
                            <label className="label-a">Activar</label>
                            <label className="switch">
                                <input id={ `checkboxPromo_${ promo.id }` } type="checkbox" defaultChecked={ promo.activo } onChange={ (e) => activarPromoBanner(promo.id) } />
                                <span className="slider round"></span>
                            </label>
                        </div>
                        <div className="cont-cont-promo">
                            <label>Texto de la promoción</label>
                            <input id={ `inputPromo_${ promo.id }` } type="text" defaultValue={ promo.promo } />
                        </div>
                        <button className="btn btn-azul" onClick={ (e) => activarPromoBanner(promo.id) }>Guardar</button>
                    </div>
                })
            }
        </div>
    </div>
}