import { useQuery } from "react-query"
import { useParams } from "react-router-dom"
import { getDatos } from "../../providers/dataServer"

export default function Compra() {
    const params = useParams()
    const { data: compra = [] } = useQuery([`compra, ${ params.id }`], () => getDatos('/products/compras/getCompra', { id: params.id }))
    return <>{
        Object.keys(compra).length > 0 ?
    <div className="cont-cotizaciones-compras">
        <h1>Detalles de Compra #{params.id}</h1>
        <div className="card-coti-info">
            <div className="card-coti-head">Detalles del Cliente</div>
            <div className="card-coti-body">
                <p><b>Cliente: </b>{ `${ compra.usuario.nombre } ${ compra.usuario.apellido }` }</p>
                <p><b>Correo: </b>{ compra.usuario.correo }</p>
                <p><b>Celular: </b>{ compra.usuario.celular }</p>
            </div>
        </div>
        <div className="card-coti-info">
            <div className="card-coti-head">Detalles de la Compra</div>
            <div className="card-coti-body">
                <p><b>ID Transacción: </b>{ compra.id_transaccion }</p>
                <p><b>Estado: </b>{ compra.estado }</p>
                <p><b>Fecha de Compra: </b>{ compra.fecha }</p>
                <p><b>Valor de compra: </b>{ compra.total }</p>
            </div>
        </div>
        <div className="card-coti-info">
            <div className="card-coti-head">Detalles del Envío</div>
            <div className="card-coti-body">
                <p><b>Empresa: </b>{ compra.detalles_envio.to_address.company_name }</p>
                <p><b>Nombre: </b>{ compra.detalles_envio.to_address.name }</p>
                <p><b>Teléfono: </b>{ compra.detalles_envio.to_address.phone }</p>
                <p><b>Calle Principal: </b>{ compra.detalles_envio.to_address.address_line1 }</p>
                <p><b>Calle Secundaria: </b>{ compra.detalles_envio.to_address.address_line2 }</p>
                <p><b>Ciudad: </b>{ compra.detalles_envio.to_address.city }</p>
                <p><b>Estado: </b>{ compra.detalles_envio.to_address.state_province }</p>
                <p><b>Codigo Postal: </b>{ compra.detalles_envio.to_address.postal_code }</p>
            </div>
        </div>
        <div className="card-coti-info">
            <div className="card-coti-head">Detalles del Producto</div>
            <table className="tabla-cont-info-producto">
                <thead>
                    <tr>
                        <th>Cantidad</th>
                        <th>Imagen</th>
                        <th>Detalle del producto</th>
                        <th>Logo</th>
                        <th>Foto</th>
                        <th>Precio</th>
                    </tr>
                </thead>
                <tbody>
                {
                    compra.detalle.map((prod, index) => {
                        return <tr key={ index }>
                        <td>{ prod.cantidad }</td>
                        <td><img style={{ width: '70px' }} src={ prod.imagen_producto } /></td>
                        <td>{ prod.nombre  }</td>
                        <td><img style={{ width: '70px' }} src={ prod.logo } /></td>
                        <td><img style={{ width: '70px' }} src={ prod.imagen } /></td>
                        <td>$ { prod.precio  }</td>
                    </tr>
                    })
                }
                </tbody>
            </table>
        </div>
    </div> :
    <div>No hay informacion de esta compra</div>}
    </>
}