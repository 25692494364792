import { Outlet, useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query'
import { getDatos } from '../providers/dataServer';
import Spiner from '../widgets/Spiner';
import { validarSesion } from '../providers/validarSesion';
import { customSwall } from '../components/CustomSwal';

function DashBoard() {

    const navigate = useNavigate();

    const { data: categorias, isLoading, isFetched } = useQuery(["categorias"], () => getDatos('/products/getCategorias'))
    if (isFetched && !!categorias?.ok) {
        if (categorias.error = 'La sesión ya no es válida o a caducado') {
            console.log('ss')
            validarSesion()
        }
        if (categorias.ok === false) {
            console.log('ssscas')
            validarSesion()
        }
    }

    function cerrarSession() {
        customSwall.fire({
            title: 'Cerrar Sesión',
            text: 'Deseas salir del sistema de administración?',
            icon: 'question',
            confirmButtonText: 'Cerrar Sesión',
            cancelButtonText: 'Cancelar',
            showCancelButton: true,
        }).then((result) => {
            if (result.isConfirmed) {
                localStorage.removeItem('tempToken');
                window.location.reload()
            }
        });
    }

    return(
        <div className='cont-app'>
            { isLoading && <Spiner title={"Cargando..."} />}
            <div className='cont-barra-lateral'>
                <div className='cont-section-barra cont-info-user'>
                    <h1 className='titulo-administrador'>Administrador de Tienda</h1>
                    <p className='bl-nombre-admin'>Bienvenid@: Administrador/a</p>
                    <button className='btn-salir-adm' onClick={ cerrarSession }>Salir</button>
                </div>
                <div className='cont-item-bl'>
                    <div className='cont-opciones-item-bl'>
                        <button className='bl-categoria-button' onClick={ () => navigate(`/`) }>Inicio</button>
                    </div>
                    <div className='cont-opciones-item-bl'>
                        <button className='bl-categoria-button' onClick={ () => navigate(`/compras`) }>Compras</button>
                    </div>
                    <div className='cont-opciones-item-bl'>
                        <button className='bl-categoria-button' onClick={ () => navigate(`/cotizaciones`) }>Cotizaciones</button>
                    </div>
                    <div className='cont-opciones-item-bl'>
                        <button className='bl-categoria-button' onClick={ () => navigate(`/configs`) }>Configuraciones</button>
                    </div>
                </div>
                <div className='cont-item-bl'>
                    <p className='bl-divisor-title'>Categorias</p>
                    <div className='cont-opciones-item-bl'>
                        {
                            categorias?.map((categoria) => {
                                return <button className='bl-categoria-button' key={ categoria.id } onClick={ () => navigate(`/productos/${categoria.cod_categoria}`) } >{ categoria.nombre }</button>
                            })
                        }
                    </div>
                </div>
            </div>
            <div className='cont-section'>
                <Outlet />
            </div>
        </div>
    )
}

export default DashBoard