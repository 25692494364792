import { useMutation } from 'react-query'
import { useForm } from 'react-hook-form'
import Spiner from '../widgets/Spiner';
import { mutarDatos } from '../providers/dataServer';
import { customSwall } from '../components/CustomSwal';
import '../assets/css/login.css'

export default function Login() {
    const { register, handleSubmit, formState: { errors }, reset } = useForm()

    const { mutate, isLoading } = useMutation({
        mutationFn: (datos) => mutarDatos({ruta: '/auth/login', parametros: datos})
    })

    function onSubmit(data) {
        mutate(data, {
            onSuccess: (res)=>{
                if (res.ok) {
                    localStorage.setItem('tempToken', res.token)
                    window.location.reload()
                } else {
                    customSwall.fire({
                        title: 'Error!',
                        text: res.error,
                        icon: 'error',
                        confirmButtonText: 'OK'
                    }).then(reset());
                }
            }
        })
    }
    return <div className="cont-login">
        {isLoading && <Spiner/>}
        <form  onSubmit={handleSubmit(onSubmit)}>
            <div className="cont-form-item cont-form-item-row-1">
                <label className='label-form-group-unit' htmlFor="usuario">Nombre del producto:</label>
                <input type="text" placeholder="usuario" { ...register('usuario', {
                    required: {
                        value: true,
                        message: 'El usuario es requerido'
                    },
                }) }/>
                <span>{ !!errors['usuario'] && errors['usuario'].message }</span>
            </div>
            <div className="cont-form-item cont-form-item-row-1">
                <label className='label-form-group-unit' htmlFor="password">Contraseña:</label>
                <input type="password" placeholder="Contraseña" { ...register('password', {
                    required: {
                        value: true,
                        message: 'El usuario es requerido'
                    },
                }) }/>
                <span>{ !!errors['password'] && errors['password'].message }</span>
            </div>
            <button type='submit'>Ingresar</button>
        </form>
    </div>
}