import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom';
import Spiner from './Spiner'
import { getDatos, mutarDatos } from '../providers/dataServer'
import '../assets/css/checkbox.css'
import { customSwall } from '../components/CustomSwal'
import { urlServerRoot } from '../providers/serverProvider';

export default function TablaProductos({ categoria }) {

    const queryClient = useQueryClient()
    const navigate = useNavigate();

    const { data: productos = [], isLoading } = useQuery(["productos", categoria], () => getDatos('/products/getProductos', {categoria: categoria}))
    const { mutate } = useMutation({
        mutationFn: (datos) => mutarDatos({ruta: '/products/activarDesactivarProducto', parametros: datos})
    })

    const { mutate: destacar } = useMutation({
        mutationFn: (datos) => mutarDatos({ruta: '/products/setDestacado', parametros: datos})
    })

    const { mutate : eliminar } = useMutation({
        mutationFn: (datos) => mutarDatos({ruta: '/products/eliminarProducto', parametros: datos})
    })

    function setActivo(e, idProducto) {
        mutate({ idProducto: idProducto, activo: e.target.checked ? 1 : 0 }, {
            onSuccess: (data) => {
                let input = document.getElementById(`checkbox-active-${ idProducto }`)
                if (data.ok) {
                    input.checked = data.activo;
                } else {
                    input.checked = !input.checked;
                }
            },
        })
    }

    function setDestacado(e, idProducto) {
        destacar({ idProducto: idProducto, destacado: e.target.checked ? 1 : 0 }, {
            onSuccess: (data) => {
                let input = document.getElementById(`checkbox-destacado-${ idProducto }`)
                if (data.ok) {
                    input.checked = data.destacado;
                } else {
                    input.checked = !input.checked;
                }
            },
        })
    }

    function eliminarProducto(idProducto) {
        customSwall.fire({
            icon: 'warning',
            title: `Eliminar Producto?`,
            text: `Estás segur@, esta acción no se puede revertir`,
            showConfirmButton: true,
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Si, Eliminar'
        }).then( response => {
            if (response.isConfirmed) {
                eliminar({ idProducto: idProducto }, {
                    onSuccess: (data) => {
                        if (data.ok) {
                            queryClient.invalidateQueries([ "productos", categoria ])
                        } else {
                            customSwall.fire({
                                icon: 'error',
                                title: 'Error',
                                text: !!data.error ? data.error : 'no se pudo eliminar',
                                showConfirmButton: true,
                            })
                        }
                    },
                })
            }
        })
    }

    return (<>
        {isLoading && <Spiner/>}
        <table id="tabla-productos" className="tabla-productos">
            <thead>
                <tr>
                    <th width={ '20px' } >#</th>
                    <th width={ '60px' } >Disponible</th>
                    <th width={ '60px' } >Destacado</th>
                    <th>Nombre</th>
                    <th width={ '40px' }>Descuento</th>
                    <th width={ '40px' }>Precio</th>
                    <th>Imagen</th>
                    <th>Acciones</th>
                </tr>
            </thead>
            <tbody>
                {
                productos?.map((p, index) =>  (
                    <tr key={ p.id }>
                        <td width={ '20px' }>{ index + 1 }</td>
                        <td width={ '60px' } >
                            <label className="switch">
                                <input id={`checkbox-active-${ p.id }`} type="checkbox" defaultChecked={ p.activo } onChange={ (e) => setActivo(e, p.id) } />
                                <span className="slider round"></span>
                            </label>
                        </td>
                        <td width={ '60px' } >
                            <label className="switch">
                                <input id={`checkbox-destacado-${ p.id }`} type="checkbox" defaultChecked={ p.destacado } onChange={ (e) => setDestacado(e, p.id) } />
                                <span className="slider round"></span>
                            </label>
                        </td>
                        <td>{ p.nombre }</td>
                        <td width={ '40px' } >{ p.descuento }</td>
                        <td width={ '40px' } >
                            { p.descuento > 0 ? p.precio_desc : p.precio }
                        </td>
                        <td width={ '50px' } className='td-image'>
                            <img src={ `${ urlServerRoot }/img-productos/${ p.carpeta }/${ JSON.parse(p.imagenes)[p.imagen]?.tumbnail }` } alt="image" />
                        </td>
                        <td className='td-acciones'>
                            <button onClick={ ()=>navigate(`/agregar-modificar-producto/${ p.id }/${ p.categoria }`) }className="btn btn-azul">Modificar</button>
                            <button onClick={ ()=>eliminarProducto(p.id) } className="btn btn-rojo">Eliminar</button>
                        </td>
                    </tr>
                )) ?? <p>No hay datos</p>}
            </tbody>
        </table>
    </>)
}