import { useQuery } from "react-query"
import { useParams } from "react-router-dom"
import { getDatos } from "../../providers/dataServer"

export default function Cotizacion() {
    const params = useParams()
    const { data: cotizacion = [] } = useQuery([`cotizacion, ${ params.id }`], () => getDatos('/products/cotizaciones/getCotizacion', { id: params.id }))
    return <>{
        Object.keys(cotizacion).length > 0 ?
    <div className="cont-cotizaciones-compras">
        <h1>Detalles de Cotizacion #{params.id}</h1>
        <div className="card-coti-info">
            <div className="card-coti-head">Detalles del Cliente</div>
            <div className="card-coti-body">
                <p><b>Cliente: </b>{ `${ cotizacion.usuario.nombre } ${ cotizacion.usuario.apellido }` }</p>
                <p><b>Correo: </b>{ cotizacion.usuario.correo }</p>
                <p><b>Celular: </b>{ cotizacion.usuario.celular }</p>
            </div>
        </div>
        <div className="card-coti-info">
            <div className="card-coti-head">Detalles de la cotización</div>
            <div className="card-coti-body">
                <p><b>ID: </b>{ cotizacion.id }</p>
                <p><b>Estado: </b>{ cotizacion.estado ? `Respondido` : `Pendiente` }</p>
                <p><b>Fecha de Solicitud: </b>{ cotizacion.fecha }</p>
                <p><b>Comentarios: </b>{ cotizacion.comentarios }</p>
            </div>
        </div>
        <div className="card-coti-info">
            <div className="card-coti-head">Detalles del Producto</div>
            <table className="tabla-cont-info-producto">
                <thead>
                    <tr>
                        <th>Cantidad</th>
                        <th>Imagen</th>
                        <th>Detalle del producto</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{ cotizacion.caracteristicas.cantidad_texto }</td>
                        <td><img style={{ width: '70px' }} src={ cotizacion.imagen_producto } /></td>
                        <td>{ cotizacion.caracteristicas.nombre }</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div className="card-coti-info">
            <div className="card-coti-head">Perzonalización</div>
            <div className="cont-imagenes-coti">
                <div className="imagen-coti">
                    <h3>Logo Personalizado</h3>
                    <img src={ cotizacion.logo } />
                </div>
                <div className="imagen-coti">
                    <h3>Foto Personalizada</h3>
                    <img src={ cotizacion.imagen } />
                </div>
            </div>
        </div>
    </div> :
    <div>No hay informacion de esta cotización</div>}
    </>
}