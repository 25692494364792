import { useQuery } from "react-query"
import { getDatos } from "../../providers/dataServer"
import { useNavigate } from "react-router-dom"

export default function Compras() {
    const navigate = useNavigate()
    const { data: compras = [] } = useQuery(["compras"], () => getDatos('/products/compras/getCompras'))
    return <div className="cont-cotizaciones-compras">
        <h1>Lista de Compras en la tienda</h1>
        <table className="tabla-coti-compra">
            <thead>
                <tr>
                    <th>Nº</th>
                    <th>Fecha</th>
                    <th>Valor</th>
                    <th>ID de compra</th>
                    <th>Estado</th>
                    <th>Acciones</th>
                </tr>
            </thead>
            <tbody>
            {
                compras?.map((compra, index) => {
                    //let caracteristicas = JSON.parse(cotizacion.caracteristicas)
                    return <tr key={ index }>
                        <td>{ index + 1 }</td>
                        <td>{ compra.fecha }</td>
                        <td>{ compra.total }</td>
                        <td>{ compra.id_transaccion }</td>
                        <td>{ compra.estado} </td>
                        <td style={{ display: 'flex' }}>
                            <button onClick={ () => navigate(`/compra/${compra.id}`) } className="btn btn-azul" style={{ fontSize: '12px', padding: '0 10px', margin: '5px', whiteSpace: 'nowrap' }}>Ver Detalles</button>
                            <button className="btn btn-rojo" style={{ fontSize: '12px', padding: '0 10px', margin: '5px' }}>Eliminar</button>
                        </td>
                    </tr>
                })
            }
            </tbody>
        </table>
    </div>
}