import { useQuery } from "react-query"
import { getDatos } from "../../providers/dataServer"
import { useNavigate } from "react-router-dom"

export default function Cotizaciones() {
    const navigate = useNavigate()
    const { data: cotizaciones = [] } = useQuery(["cotizaciones"], () => getDatos('/products/cotizaciones/getCotizaciones'))
    return <div className="cont-cotizaciones-compras">
        <h1>Lista de Cotizaciones</h1>
        <table className="tabla-coti-compra">
            <thead>
                <tr>
                    <th>Nº</th>
                    <th>Fecha</th>
                    <th>Cliente</th>
                    <th>Producto</th>
                    <th>Cantidad</th>
                    <th>Estado</th>
                    <th>Acciones</th>
                </tr>
            </thead>
            <tbody>
            {
                cotizaciones?.map((cotizacion, index) => {
                    let caracteristicas = JSON.parse(cotizacion.caracteristicas)
                    return <tr key={ index }>
                        <td>{ index + 1 }</td>
                        <td>{ cotizacion.fecha }</td>
                        <td>{ `${ cotizacion.usuario.nombre } ${ cotizacion.usuario.apellido }` }</td>
                        <td>{ caracteristicas.nombre }</td>
                        <td>{ caracteristicas.cantidad_texto}</td>
                        <td>{ cotizacion.estado ? `Respondido` : `Pendiente` }</td>
                        <td style={{ display: 'flex' }}>
                            <button onClick={ () => navigate(`/cotizacion/${cotizacion.id}`) } className="btn btn-azul" style={{ fontSize: '12px', padding: '0 10px', margin: '5px', whiteSpace: 'nowrap' }}>Ver Detalles</button>
                            <button className="btn btn-rojo" style={{ fontSize: '12px', padding: '0 10px', margin: '5px' }}>Eliminar</button>
                        </td>
                    </tr>
                })
            }
            </tbody>
        </table>
    </div>
}